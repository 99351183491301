import Service from './Service';
import {isEmpty} from 'lodash';

const resource = '/api/diagnostico';

export default {
    search(search = '', pagination = {}, sexo = '') {

        let url = `${resource}/search?`;

        url += search !== '' ? `s=${search}` : '';
        url += sexo !== '' ? `&sexo=${sexo}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);

    },
    getByCode(code, sexo=''){

        let url = `${resource}/get?by=codigo&codigo=${code}`;

        url += sexo != '' ? `&sexo=${sexo}` : '';

        return Service.get(url);

    },
    index(search = '', pagination = {}, sexo = '') {

        let url = `${resource}?`;

        url += search !== '' ? `s=${search}` : '';
        url += sexo !== '' ? `&sexo=${sexo}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);

    },
    show(id){
        return Service.get(`${resource}/show/${id}`);
    }
}
